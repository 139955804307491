import { get, post } from './index';

async function userAttr(func) {
    await get('/api/userAttr').then(response => {
    	if(response){
    		return func(response.data)
    	}else{
    		return false
    	}
    });
}

async function setUserAttr(func, param) {
    await post('/api/userAttr', param).then(response => {
    	if(response){
    		return func(response.data)
    	}else{
    		return false
    	}
    });
}

export default {
    userAttr,
    setUserAttr
}