import { get } from './index';

function saleList(func) {
    get('/api/filter/saleList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function developerList(func) {
    get('/api/filter/developerList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function managerList(func) {
    get('/api/filter/managerList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function propertyList(func) {
    get('/api/filter/propertyList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function areaList(func) {
    get('/api/filter/areaList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

export default {
    saleList,
    developerList,
    managerList,
    propertyList,
    areaList
}