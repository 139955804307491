import React, { Component } from 'react';
import { Layout } from 'antd';

import homeApi from '../api/home';

const { Header } = Layout;

export default class Nav extends Component {

	constructor(props){
		super(props);

		var timestamp =new Date().getTime()
		this.state = { 
		  	user: {},
		  	listLink: "/list?timestamp=" + timestamp
		}
	}

	// 
	async componentDidMount() {
		// 
		await homeApi.currentSale( response => {
			this.setState({ user: response || '' });
		});
	}

	render(){

		const logout = () => {
			window.localStorage.setItem('jwtToken', '');
			window.location.href = "/";
		}

		return (
			<div id="Nav">
				<Header>
	                <a href={this.state.listLink} ><div id="logo"></div></a>
	                <div id="login">
	                	<div>
	                		头像：{ this.state.user.name }
	                	</div>
	                	<div>
	                		|
	                	</div>
	                	<div >
	                		<a onClick={logout}>
	                			退出登录
	                		</a>
	                	</div>
	                </div>
	                <div id="clear"></div>
	            </Header>
            </div>
		)
	}
}