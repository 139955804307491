import React, { Component } from 'react';
//	react-dnd
import { Link } from 'react-router-dom';
import { Drawer, Button, Select, Checkbox, Card, Col, Row, Input, Table, Space, Image, Typography, Collapse, Pagination } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import Nav from './Nav';

import infoApi from '../api/info';
import saleApi from '../api/sale';
import attrApi from '../api/attr';
import filterApi from '../api/filter';

import 'antd/dist/antd.css';

const { Option } = Select;
const { Title } = Typography;
const { Panel } = Collapse;

export default class Lists extends Component {

	constructor(props){
		super(props);
		this.state = { 
			columns:[],
		  	data: [],
		  	list: [],

		  	saleList: [],
		  	developerList: [],
		  	managerList: [],
		  	areaList: [],
		  	propertyList: [],
		  	dateList: [],
		  	typeList: [],

		  	saleValue: [],
		  	areaValue: [],
		  	developerValue: [],
		  	managerValue: [],
		  	propertyValue: [],
		  	dateValue: [],
		  	typeValue: [],

		  	visible: false,
			searchText: '',
			searchedColumn: '',

			page: 1,
			pageSize: 10,
		}

		var columns = [
		  {
		    title: '应用LOGO',
		    width: 170,
		    dataIndex: 'logo',
		    key: '0',
		    fixed: 'left',
		    render: (row, obj) => { 
		    	return <Space size={12}>
					<Image
						width={128}
						src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + obj.thumbnail}
						placeholder={
							<Image
								preview={false}
								src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + obj.thumbnail}
							/>
						}
					/>
				</Space>
		  	},
		  },
		  {
		    title: '应用名称',
		    width: 200,
		    dataIndex: 'name',
		    key: '0',
		    fixed: 'left',
		    render: (row, obj) => { 
		    	return <Space size={12}>
					<Title level={4}>{row}</Title>
				</Space>
		  	},
		  },
		];

		saleApi.userAttr( response => {

			console.log("userAttr", response);

			for (var i in response) {

				// 排序
				var sortfunc = {
					compare: (a, b) => {
						return true
					}
			      	//compare: (a, b) => a.type_name - b.type_name
			    }
			    var filtersfunc = false

			    var dataIndex = response[i].dataIndex
				if(dataIndex == "type_name"){
					// sortfunc = {
				 //      	//compare: (a, b) => a.type_name - b.type_name
				 //    }
				    filtersfunc = this.getColumnSearchProps('type_name')
				} else if ( dataIndex == "update_date"){
					// sortfunc = {
				 //      	//compare: (a, b) => a.update_date - b.update_date
				 //    }
				    filtersfunc = this.getColumnSearchProps('update_date')
				}

				let tabelList = {
					title: response[i].title,
					dataIndex: response[i].dataIndex,
					sorter: sortfunc,
					sortDirections: ['descend'],
					// showSorterTooltip: false,
					// filters: [
				 //      { text: 'Male', value: 'male' },
				 //      { text: 'Female', value: 'female' },
				 //    ],
				    ...filtersfunc,
					key: i,
					//fixed: 'left',
					//width: 80,
				};
				columns.push(tabelList);
			}

			const tabelAction = {
				title: '操作',
				key: 'operation',
				fixed: 'right',
				width: 200,
				render: (row) => {
					return <div><a id="listedit" href={row.link}></a></div>
				},
			};

			columns.push(tabelAction);
			console.log("columns", columns);
			this.setState({ columns: columns || '' });
		});
	}

	// 
	async componentDidMount() {

		await infoApi.list( response => {
			let data = [];
			var timestamp =new Date().getTime()
			for (var i in response.data) {
            	data[i] = response.data[i]
            	data[i].key = i
            	data[i].link = "/info/" + response.data[i].id + "?timestamp=" + timestamp

            	if (data[i].type == 1 || data[i].type == 2){
					data[i].download_link 	= "https://appdownload.fzsdmjh5.com/download/" + data[i].id + "?timestamp=" + timestamp
				}
            }
			this.setState({ data: data || '' });
		});

		await filterApi.saleList( response => {
			let saleList = response
			this.setState({ saleList: saleList || '' })
		});

		await filterApi.developerList( response => {
			let developerList = response
			this.setState({ developerList: developerList || '' })
		});

		await filterApi.managerList( response => {
			let managerList = response
			this.setState({ managerList: managerList || '' })
		});

		await filterApi.areaList( response => {
			let areaList = response;
			this.setState({ areaList: areaList || '' });
		});

		await filterApi.propertyList( response => {
			let propertyList = response;
			this.setState({ propertyList: propertyList || '' });
		});

		// 
		let dateList = [
			{
				"id": 1,
				"name": "一天内"
			},
			{
				"id": 2,
				"name": "二天内"
			},
			{
				"id": 7,
				"name": "一周内"
			},
			{
				"id": 14,
				"name": "二周内"
			},
			{
				"id": 30,
				"name": "一个月内"
			},
			{
				"id": 183,
				"name": "半年内"
			},
			{
				"id": 365,
				"name": "一年内"
			}
		];
		this.setState({ dateList: dateList || '' });

		// 
		let typeList = [
			{
				"id": 1,
				"name": "IOS"
			},
			{
				"id": 2,
				"name": "安卓"
			},
			{
				"id": 3,
				"name": "PC端"
			},
			{
				"id": 4,
				"name": "H5端"
			}
		];
		this.setState({ typeList: typeList || '' });

	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
		    <Input
		      ref={node => {
		        this.searchInput = node;
		      }}
		      placeholder={`搜索 ${dataIndex}`}
		      value={selectedKeys[0]}
		      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
		      onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
		      style={{ width: 188, marginBottom: 8, display: 'block' }}
		    />
		    <Space>
		      <Button
		        type="primary"
		        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
		        icon={<SearchOutlined />}
		        size="small"
		        style={{ width: 90 }}
		      >
		        搜索
		      </Button>
		      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
		        重置
		      </Button>
		      <Button
		        type="link"
		        size="small"
		        onClick={() => {
		          confirm({ closeDropdown: false });
		          this.setState({
		            searchText: selectedKeys[0],
		            searchedColumn: dataIndex,
		          });
		        }}
		      >
		        过滤
		      </Button>
		    </Space>
		  </div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		  	record[dataIndex]
		    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		    : '',
		onFilterDropdownVisibleChange: visible => {
		  	if (visible) {
		    	setTimeout(() => this.searchInput.select(), 100);
		  	}
		},
		render: text =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
				  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				  searchWords={[this.state.searchText]}
				  autoEscape
				  textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	//
	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	};

	//
	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	showDrawer = () => {
		//调用接口
		attrApi.allAttr( response => {

			console.log("allAttr", response);

			let list = [];
			for (var i in response) {
				let listitem = response[i]
				listitem.key = i
				listitem.status = listitem.attr_id ? 1 : 0
				list[i] = {
					key: listitem.key,
					title: listitem.title,
					attr_id: listitem.id,
					status: listitem.status,
					checkAttr: () => {
						if(listitem.key){
							if(list[listitem.key].status == 0){
								list[listitem.key].status = 1
							}else{
								list[listitem.key].status = 0
							}
						}
						this.setState({
							list: list,
						});
						console.log(this.state.list);
					}
				}
			}

			this.setState({
				visible: true,
				list: list,
			});

		});
	};

	// 提交
	onSubmit = () => {

		saleApi.setUserAttr( (response) => {

			if( response ){
				var timestamp =new Date().getTime()
				window.location.href = "/list?timestamp=" + timestamp
				this.setState({
					visible: false,
				});
			}else{

			}

		}, this.state.list)
		
	};

	// 取消
	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	onPage = ( page, pageSize) => {
		this.setState({
			page: page,
			pageSize: pageSize
		});
	};

	onPageSize = ( current, size) => {

	};

	render(){

		var orderParam = ""

		// 排序
		const handleChange = (item) => {
			orderParam = item.value
			handleList()
		}

		// 
		const handleSaleChange = (value) => {
			this.setState({ saleValue: value || [] })
		}

		const handleDeveloperChange = (value) => {
			this.setState({ developerValue: value || [] })
		}

		const handleManagerChange = (value) => {
			this.setState({ managerValue: value || [] })
		}

		const handleAreaChange = (value) => {

			let areaId = 0
			for (var i in this.state.areaList) {
		  		let item = this.state.areaList[i]
		  		for (var j in value) {
		  			let name = value[j]
			  		if ( item.name == name ){
			  			areaId = item.id
			  		}
			  	}
		  	}

		  	let propertyValue = []
			for (var i in this.state.propertyList) {
		  		let item = this.state.propertyList[i]
			  	if ( item.area_id == areaId ){
			  		propertyValue.push(item.name)
			  	}
		  	}

			this.setState({ areaValue: value || [] })
			this.setState({ propertyValue: propertyValue })
		}

		const handlePropertyChange = (value) => {
			
			let propertyArray = []
			let propertyData = []
			for (var i in this.state.propertyList) {
				let item = this.state.propertyList[i]

				if ( propertyArray[item.area_id] == undefined ) {
					propertyArray[item.area_id] = []
				}
				propertyArray[item.area_id].push(item)

			  	for (var j in value) {
		  			let name = value[j]
			  		if ( item.name == name ){

			  			if ( propertyData[item.area_id] == undefined ) {
							propertyData[item.area_id] = []
						}
			  			propertyData[item.area_id].push(item)
			  		}
			  	}
		  	}

		  	let areaArray = []
		  	for (var k in propertyArray) {
		  		let node = propertyArray[k]
		  		if ( propertyData[k] !== undefined && node.length == propertyData[k].length ) {
		  			for (var y in node ){
		  				areaArray.push(node[y].area_id)
		  				break
		  			}
		  		}
		  	}

		  	let areaValue = []
		  	for (var i in this.state.areaList) {
		  		let item = this.state.areaList[i]
		  		for (var j in areaArray) {
		  			let area_id = areaArray[j]
			  		if ( item.id == area_id ){
			  			areaValue.push(item.name)
			  		}
			  	}
		  	}

			this.setState({ propertyValue: value || [] })
			this.setState({ areaValue: areaValue })
		}

		const handleDateChange = (value) => {
			this.setState({ dateValue: value || [] })
		}

		const handleTypeChange = (value) => {
			this.setState({ typeValue: value || [] })
		}

		// 开始搜索
		const handleOnSearch = (value) => {
			handleList()
		}

		const handleOnEmpty = (value) => {
			this.setState({ saleValue: [] })
			this.setState({ developerValue: [] })
			this.setState({ managerValue: [] })
			this.setState({ areaValue: [] })
			this.setState({ propertyValue: [] })
			this.setState({ dateValue: [] })
			this.setState({ typeValue: [] })
		}

		const handleList = () => {

			let saleIds = []
			for (var i in this.state.saleList) {
		  		let item = this.state.saleList[i]
		  		for (var j in this.state.saleValue) {
		  			let name = this.state.saleValue[j]
			  		if ( item.name == name ){
			  			saleIds.push(item.id)
			  		}
			  	}
		  	}

		  	let developerIds = []
			for (var i in this.state.developerList) {
		  		let item = this.state.developerList[i]
		  		for (var j in this.state.developerValue) {
		  			let name = this.state.developerValue[j]
			  		if ( item.name == name ){
			  			developerIds.push(item.id)
			  		}
			  	}
		  	}

		  	let managerIds = []
			for (var i in this.state.managerList) {
		  		let item = this.state.managerList[i]
		  		for (var j in this.state.managerValue) {
		  			let name = this.state.managerValue[j]
			  		if ( item.name == name ){
			  			managerIds.push(item.id)
			  		}
			  	}
		  	}

		  	let areaIds = []
			for (var i in this.state.areaList) {
		  		let item = this.state.areaList[i]
		  		for (var j in this.state.areaValue) {
		  			let name = this.state.areaValue[j]
			  		if ( item.name == name ){
			  			areaIds.push(item.id)
			  		}
			  	}
		  	}

		  	let propertyIds = []
			for (var i in this.state.propertyList) {
		  		let item = this.state.propertyList[i]
		  		for (var j in this.state.propertyValue) {
		  			let name = this.state.propertyValue[j]
			  		if ( item.name == name ){
			  			propertyIds.push(item.id)
			  		}
			  	}
		  	}

		  	let dateIds = []
			for (var i in this.state.dateList) {
		  		let item = this.state.dateList[i]
		  		for (var j in this.state.dateValue) {
		  			let name = this.state.dateValue[j]
			  		if ( item.name == name ){
			  			dateIds.push(item.id)
			  		}
			  	}
		  	}

		  	let typeIds = []
			for (var i in this.state.typeList) {
		  		let item = this.state.typeList[i]
		  		for (var j in this.state.typeValue) {
		  			let name = this.state.typeValue[j]
			  		if ( item.name == name ){
			  			typeIds.push(item.id)
			  		}
			  	}
		  	}

			let params = {
				sale: saleIds,
				developer: developerIds,
				manager: managerIds,
				area: areaIds,
				property: propertyIds,
				date: dateIds,
				type: typeIds,
				order: orderParam
			}

			infoApi.list( response => {
				let data = []
            	var timestamp =new Date().getTime()
				for (var i in response.data) {
	            	data[i] = response.data[i]
	            	data[i].key = i
	            	data[i].link = "/info/" + response.data[i].id + "?timestamp=" + timestamp

	            	if (data[i].type == 1 || data[i].type == 2){
						data[i].download_link 	= "https://appdownload.fzsdmjh5.com/download/" + data[i].id + "?timestamp=" + timestamp
					}
	            }
				this.setState({ data: data || '' })
			}, params)
		}

		var attrButton = [];
		for (var i = 0; i < this.state.list.length; i++) {
			let item = this.state.list[i]
			if(item.status == 1){
				attrButton.push(
					<Button key={item.key} onClick={item.checkAttr} type="primary">
			        	{item.title}
			        </Button>
		        )
			}else{
				attrButton.push(
					<Button key={item.key} onClick={item.checkAttr} type="default">
			        	{item.title}
			        </Button>
		        )
			}
		}

		const saleOptions = []
	  	for (var i = 0; i < this.state.saleList.length; i++) {
	  		let item = this.state.saleList[i];
	  		saleOptions.push(
	  			item.name
	  		)
	  	}

	  	const developerOptions = []
	  	for (var i = 0; i < this.state.developerList.length; i++) {
	  		let item = this.state.developerList[i];
	  		developerOptions.push(
	  			item.name
	  		)
	  	}

	  	const managerOptions = []
	  	for (var i = 0; i < this.state.managerList.length; i++) {
	  		let item = this.state.managerList[i];
	  		managerOptions.push(
	  			item.name
	  		)
	  	}

	  	const areaOptions = []
	  	for (var i = 0; i < this.state.areaList.length; i++) {
	  		let item = this.state.areaList[i];
	  		areaOptions.push(
	  			item.name
	  		)
	  	}

	  	const propertyOptions = []
	  	for (var i = 0; i < this.state.propertyList.length; i++) {
	  		let item = this.state.propertyList[i];
	  		propertyOptions.push(
	  			item.name
	  		)
	  	}

	  	const dateOptions = []
	  	for (var i = 0; i < this.state.dateList.length; i++) {
	  		let item = this.state.dateList[i];
	  		dateOptions.push(
	  			item.name
	  		)
	  	}

	  	const typeOptions = []
	  	for (var i = 0; i < this.state.typeList.length; i++) {
	  		let item = this.state.typeList[i];
	  		typeOptions.push(
	  			item.name
	  		)
	  	}

		return (
			<div id="list">
				
				<Nav/>
				<br/>

                <div id="list_content">

	                <Collapse defaultActiveKey={['0']}>

						<Panel header="数据检索" key="1">
		                	
							<Card title="Card">

								<Row>
									<Space>
										<button id="button" onClick={handleOnSearch}>确认搜索</button>
										<button id="button" onClick={handleOnEmpty}>清空选择</button>
									</Space>
								</Row>

								<Row>
									<Col span={4}>销售</Col>
									<Col span={20}>
							  			<Checkbox.Group options={saleOptions} value={this.state.saleValue} onChange={handleSaleChange}>
										</Checkbox.Group>
									</Col>
								</Row>

								<Row>
									<Col span={4}>开发人员</Col>
									<Col span={20}>
							  			<Checkbox.Group options={developerOptions} value={this.state.developerValue} onChange={handleDeveloperChange}>
										</Checkbox.Group>
									</Col>
								</Row>

								<Row>
									<Col span={4}>项目经理</Col>
									<Col span={20}>
							  			<Checkbox.Group options={managerOptions} value={this.state.managerValue} onChange={handleManagerChange}>
										</Checkbox.Group>
									</Col>
								</Row>

								<Row>
									<Col span={4}>地区</Col>
									<Col span={20}>
							  			<Checkbox.Group options={areaOptions} value={this.state.areaValue} onChange={handleAreaChange}>
										</Checkbox.Group>
									</Col>
								</Row>

								<Row>
									<Col span={4}>地产</Col>
									<Col span={20}>
							  			<Checkbox.Group options={propertyOptions} value={this.state.propertyValue} onChange={handlePropertyChange}>
										</Checkbox.Group>
									</Col>
								</Row>

								<Row>
									<Col span={4}>时间</Col>
									<Col span={20}>
							  			<Checkbox.Group options={dateOptions} value={this.state.dateValue} onChange={handleDateChange}>
										</Checkbox.Group>
									</Col>
								</Row>
								
								<Row>
									<Col span={4}>平台</Col>
									<Col span={20}>
							  			<Checkbox.Group options={typeOptions} value={this.state.typeValue} onChange={handleTypeChange}>
										</Checkbox.Group>
									</Col>
								</Row>

							</Card>
							
						</Panel>

					</Collapse>

					<div id="clear"></div>
					<br/>

                	<div id="edit" >
				        <Select
				        	id="info-select"
							labelInValue
							defaultValue={{ value: 'type' }}
							style={{ width: 150 }}
							onChange={handleChange}
						>
							<Option value="type">按平台排序</Option>
							<Option value="version">按版本排序</Option>
							<Option value="update_time">按时间排序</Option>
							<Option value="encryption_period">按加密周期排序</Option>
						</Select>
						<a type="primary" onClick={this.showDrawer}>
				    		编辑
				        </a>
			        </div>

			        <Pagination 
			        	total={this.state.data.length} 
			        	current={this.state.page} 
			        	pageSize={this.state.pageSize} 
			        	onChange={this.onPage} 
			        	onPageSize={this.onPageSize}
			        	showSizeChanger={true}
			        />

			        <Table
					    columns={this.state.columns}
					    dataSource={this.state.data}
					    locale={{
					    	cancelSort: "点击升序",
					    	triggerAsc: "点击升序",
					    	triggerDesc: "点击降序"
					    }}
					    pagination={{
					    	current: this.state.page,
					    	pageSize: this.state.pageSize,
					    	onChange: this.onPage,
			        		onPageSize: this.onPageSize,
					      	showSizeChanger: true
					    }}
					    bordered
					    size="default"
					    scroll={{ x: 'calc(1000px + 50%)' }}
					/>
				</div>
					
				<Drawer
		          	title="设置销售信息"
		          	width={720}
		          	onClose={this.onClose}
		          	visible={this.state.visible}
		          	getContainer={false}
		          	bodyStyle={{ paddingBottom: 80 }}
		          	footer={
			            <div style={{ textAlign: 'right' }} >
			              	<Button onClick={this.onClose} style={{ marginRight: 8 }}>
			                	取消
			              	</Button>
			              	<Button onClick={this.onSubmit} type="primary">
			                	提交
			              	</Button>
			            </div>
		          	}
		        >
			        <Space wrap>{attrButton}</Space>
		        </Drawer>

			</div>
		)
	}
}