import { get } from './index';

function list(func, info_id) {
    get('/api/infoVersion?info_id=' + info_id).then(response => func(response.data) );
}

async function addClickNumber(func, info_id) {
    get('/api/addClickNumber?info_id=' + info_id).then(response => func(response.data) );
}

export default {
    list,
    addClickNumber
}