import { get, post } from './index';

async function list(func, params) {
    await post('/api/list', params).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

async function show(func, id) {
    await get('/api/info?id=' + id).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

async function downInfo(func, id) {
    await get('/api/downInfo?id=' + id).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

async function checkCode(func, params) {
    await post('/api/checkCode', params).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

export default {
    list,
    show,
    downInfo,
    checkCode
}