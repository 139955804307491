import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Modal, Input, Table, Image, Descriptions, List, Avatar, Space } from 'antd';

import Nav from './Nav';

import infoApi from '../api/info';
import infoVersionApi from '../api/infoVersion';

import 'antd/dist/antd.css';
import { SearchOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;

const columns = [
  {
    title: '版本',
    dataIndex: 'name',
    key: '1',
    width: 150,
  },
  {
    title: 'build（打包次数）',
    dataIndex: 'build',
    key: '2',
    width: 150,
  },
  {
    title: '大小',
    dataIndex: 'app_size',
    key: '3',
    width: 150,
  },
  { title: '下载次数', dataIndex: 'download_number', key: '4' },
  { title: '更新时间', dataIndex: 'date', key: '5' },
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (row) => {
    	return <Link id="infoedit" to={row.link}></Link>
    },
  },
];

export default class Info extends Component {

	constructor(props){
		super(props);
		this.state = { 
		  	data: {
		  		link: '',
		  	},
				allList:[],
		  	versionList: [],
		  	propertyList: [],

		  	visible: false,
		}
	}

	// 
	async componentDidMount() {

		// 
		await infoApi.list( response => {
			let propertyList = [];
			var timestamp =new Date().getTime()
			for (var i in response.data) {
      	propertyList[i] = response.data[i]
      	propertyList[i].key = i
      	propertyList[i].link = "/info/" + response.data[i].id + "?timestamp=" + timestamp
      }
			this.setState({ allList: propertyList || '' });
			this.setState({ propertyList: propertyList || '' });
		});

		// 详情
		let id = this.props.match.params.id || 1;
		var timestamp =new Date().getTime()
		infoApi.show( response => {
			let data = response
			data.title 	= response.name
			data.link 	= "/download/" + response.id + "?timestamp=" + timestamp

			if (data.type === 1 || data.type === 2){
					data.download_link 	= "https://appdownload.fzsdmjh5.com/download/" + response.id + "?timestamp=" + timestamp
			}

			this.setState({ data: data || '' });
		}, id);

		// 版本列表
		infoVersionApi.list( response => {
			let versionList = [];
			var timestamp =new Date().getTime()
            for (var i in response.data) {
            	versionList[i] = response.data[i]
            	versionList[i].key = i
            	versionList[i].link = "/download/" + response.data[i].info_id + "?timestamp=" + timestamp
            }
			this.setState({ versionList: versionList || '' });
		}, id);
	}

	showModal = () => {
    this.setState({
				visible: true,
		});
  };

  handleOk = () => {
    this.setState({
				visible: false,
		});
  };

  handleCancel = () => {
    this.setState({
				visible: false,
		});
  };

	render(){

		const searchApp = () => {
			let propertyList = [];
			for (var i in this.state.allList) {
				let item = this.state.allList[i]
				if ( item.name.indexOf(this.search.state.value) !== -1 ){
					propertyList.push(item)
				}
			}
			this.setState({ propertyList: propertyList || '' });
		}

		const explainList = []
		for (var j in this.state.versionList) {
				let item = this.state.versionList[j]
				explainList.push(
					<p key={item.id}>
						<Space>
							<span>{item.build}</span>
							<span>{item.date}</span>
							<span>{item.explain}</span>
						</Space>
					</p>
				)
		}

		return (
			<div id="info">

				<Nav></Nav>

                <div id="info_content">
					<Layout>

				      	<Sider
					      	style={{
						        overflow: 'auto',
						        height: '100vh',
						      }}
				      	>

				      		<Input size="large" placeholder="搜索您的应用" prefix={<SearchOutlined />} ref={(c) => this.search = c} onKeyUp={searchApp} />

				      		<List
							    itemLayout="horizontal"
							    dataSource={this.state.propertyList}
							    renderItem={item => (
							      <List.Item>
							        <List.Item.Meta
							          avatar={<Avatar src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + item.thumbnail} />}
							          title={<a href={item.link}>{item.name}</a>}
							          description={item.type_name}
							        />
							      </List.Item>
							    )}
							  />

				      	</Sider>

				      	<Content >
				      	
				      		<div id="infocontent">
								<Descriptions layout="vertical" column={{ xxl: 5, xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }} bordered>

									<Descriptions.Item style={{ width: '240px' }} >
										<div id="info_image"> 
											<Image
												width={128}
												height={128}
												src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + this.state.data.thumbnail}
											/>
										</div>  
										<br />
										<div>
											名称： {this.state.data.title} 
											<br />
											<br />
											类型：{this.state.data.type_name}
										</div>
									</Descriptions.Item>

									<Descriptions.Item style={{ width: '240px' }} >
										初稿完成日期：{this.state.data.developer_time}
										<br /><br />
										程序更新时间：{this.state.data.update_date}
										<br /><br />
										终稿完成日期：{this.state.data.final_time}
									</Descriptions.Item>
									
									<Descriptions.Item span={2}>
										应用介绍
										<br />
										<br />
										{this.state.data.details}
									</Descriptions.Item>

									<Descriptions.Item>
										<a id="downbtn" href={this.state.data.link}></a>
									</Descriptions.Item>

									<Descriptions.Item>
										开发人员
										<br />
										{this.state.data.developer_name}
									</Descriptions.Item>

									<Descriptions.Item>
										项目经理
										<br />
										{this.state.data.manager_name}
									</Descriptions.Item>

									<Descriptions.Item>
										应用介绍与更新说明
										<br />
										<a type="primary" onClick={this.showModal}>
						    			查看详情
						        </a>
									</Descriptions.Item>

									<Descriptions.Item>
										加密周期
										<br />
										{this.state.data.encryption_period}

									</Descriptions.Item>

									<Descriptions.Item>
										项目销售
										<br />
										{this.state.data.sale_name}
									</Descriptions.Item>

									<Descriptions.Item>
										开发完成时间
										<br />
										{this.state.data.developer_time}
									</Descriptions.Item>

									<Descriptions.Item>
										版本信息
										<br />
										{this.state.data.title}
									</Descriptions.Item>

									<Descriptions.Item span={2}>
										下载链接
										<br />
										{this.state.data.download_link}
									</Descriptions.Item>

									<Descriptions.Item>
										下载验证码
										<br />
										*
									</Descriptions.Item>

								</Descriptions>
							</div>

							<div >
								历史版本
							</div>

							<div id="info_table">
								<Table
                    columns={columns}
                    dataSource={this.state.versionList}
                    bordered={false}
                    pagination={false}
                    size="middle"
                />
              </div>

              <Modal title="应用介绍与更新说明" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
				        {explainList}
				      </Modal>

						</Content>
					</Layout>
				</div>

			</div>
		)
	}
}