import React, { Component } from 'react';
import { Layout } from 'antd';

import logoImage from '../assets/logo.png'

const { Header } = Layout;

export default class NavH5 extends Component {

	constructor(props){
		super(props);
		this.state = { 
			listLink: ""
		}
	}

	async componentDidMount() {
		var id = this.props.infoId
		var timestamp =new Date().getTime()
		this.state.listLink = "/info/"+id+"?timestamp=" + timestamp
	}

	render(){

		let H5Header
		let jwtToken = window.localStorage.getItem('jwtToken');
        if (jwtToken) {
            H5Header = <Header>
	                <div id="logo">
	                	<a href={this.state.listLink} >
		                	<img 
								src={logoImage}
	                    	/>
                    	</a>
	                </div>
	                <div id="clear"></div>
	            </Header>
        } else {
            H5Header = <Header>
	                <div id="logo">
						<img 
							src={logoImage}
                    	/>
					</div>
	            </Header>
        }

		return (
			<div id="NavH5">
				{H5Header}
            </div>
		)
	}
}