import axios from 'axios';

let BASE_URL;

if(process.env.NODE_ENV == 'development'){
    BASE_URL = 'http://localhost:7001'
}else{
    // BASE_URL = 'http://192.168.234.130:8080'
    // BASE_URL = 'http://sdmj_appdownload_api.com'
    BASE_URL = 'https://appdownloadapi.fzsdmjh5.com'
}

//拦截器，在向后台发出请求的时候，可以动态的修改配置
axios.interceptors.request.use((config) => {
    let jwtToken = window.localStorage.getItem('jwtToken');
    if (jwtToken) {
        config.headers.authorization = `Bearer ${jwtToken}`;
    }
    //console.log("jwtToken", jwtToken);
    return config;
});

axios.interceptors.response.use(res => {    
    // if (res.data.code !== 0) {
    //     return Promise.reject(res.data.error);
    // }
    return res;
}, error => {
    if ( error.response && error.response.status == 401 ) {
        window.localStorage.setItem('jwtToken', '');
        window.location.href = "/"
    }
    // if (error.response && error.response.status >= 400 && error.response.status > 500) {
    // }
    // return Promise.reject(error.response.data.message);
});

export async function get(url) {
    return await axios.get(BASE_URL + url);
}

export async function post(url, body) {
    return await axios.post(BASE_URL + url, body);
}