import { post, get } from './index';

function login(func, params) {
	params.mobile = Number.parseInt(params.mobile)
    post('/api/login', params).then(response => func(response) );
}

function currentSale(func) {
    get('/api/currentSale').then(response => {
    	if(response){
    		return func(response.data)
    	}else{
    		return false
    	}
    });
}

export default {
    login,
    currentSale
}