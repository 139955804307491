import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Layout } from 'antd';

import Home from './page/Home';
import Lists from './page/Lists';
import Info from './page/Info';
import Download from './page/Download';

const { Header, Content } = Layout;

class App extends Component {
    render() {
        return (
            <Layout className="layout " style={{ height: '100%' }}>

                <Content style={{ padding: '0' }}>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/list" component={Lists}/>
                    <Route exact path="/info/:id" component={Info}/>
                    <Route exact path="/download/:id" component={Download}/>
                </Content>

            </Layout>
        );
    }
}

export default App;
