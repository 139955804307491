import React, { Component } from 'react';
import { Table, Image, Modal, Input, notification } from 'antd';

import NavH5 from './NavH5';

import QRCode  from 'qrcode.react';

import '../App.css';
import infoApi from '../api/info';
import infoVersionApi from '../api/infoVersion';

const columns = [
  {
    //title: 'Build',
    dataIndex: 'build',
    width: "50%",
  },
  { 
    //title: '更新时间', 
    dataIndex: 'date',
    width: "50%",
  },
];

class App extends Component {

    constructor(props){
        super(props);
        this.state = { 
            url: "",
            info: {
                link: '',
                name: '',
                codeImage: {} 
            },
            versionList: [],
            isModalVisible: false,
            is_secret: true,
            code: ''
        };
    }

    componentDidMount(){
        let id = this.props.match.params.id || 1
        var timestamp =new Date().getTime()
        let url = "https://appdownload.fzsdmjh5.com/download/" + id  + "?timestamp=" + timestamp;
        this.setState({ url: url || '' });
        infoApi.downInfo( response => {

            response.link = ''
            if(response.info_version){
                response.build = response.info_version.name

                // ios
                if(response.type === 1){
                    const link = "https://appdownloadapi.fzsdmjh5.com/public/xml/plist_" + response.info_version.id + ".xml";
                    response.link = "itms-services://?action=download-manifest&url=" + link || '';

                // android
                }else if(response.type === 2) {

                    if ( response.upload_type == 1 ) {
                        response.link = "https://h5.fzsdmjh5.com/appdownload/app/" + response.info_version.app_link || '';
                    } else {
                        response.link = "https://sdmjappdownload.oss-cn-hangzhou.aliyuncs.com/" + response.info_version.app_link || '';
                    }
                    
                }

            }else{
                response.build = ''
            }

            let codeImage = {
                src: "http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + response.thumbnail,
                width: 45,
                height: 45,
                imageExcavate: true,
            }

            response.codeImage = codeImage

            this.setState({ info: response || '' });
        }, id);

        // 
        infoVersionApi.list( response => {
            let versionList = [];
            for (var i in response.data) {
                versionList[i] = response.data[i]
                versionList[i].key = i
                versionList[i].link = "/download/" + response.data[i].info_id
            }
            this.setState({ versionList: versionList || '' });
        }, id);

    }

    checkCode = () => {

        if ( this.state.info.secret_key === this.state.code ) {
            this.hrefLink()
        } else {
             console.log("error")
            notification["warning"]({
                message: '操作失败',
                description:
                    '验证码不正确',
            });
        }

        // let id = this.props.match.params.id || 1
        // infoApi.checkCode( response => {
        //     if ( response.data == true ){
        //         this.hrefLink()
        //     } else {
        //         console.log("error")
        //         notification["warning"]({
        //             message: '操作失败',
        //             description:
        //                 '验证码不正确',
        //         });
        //     }
        // }, {
        //     id: id,
        //     code: this.state.code
        // })

    };

    hrefLink = () => {
        var link = document.getElementById('href-link');

        // 
        this.addClickNumber()

        link.click();
    }

    // 
    addClickNumber = () => {
        let id = this.props.match.params.id || 1
        infoVersionApi.addClickNumber( response => {
            console.log("打点成功")
        }, id);
    }

    // 
    render() {

        const showModal = (e) => {
            if ( this.state.info.is_secret == true ) {
                this.setState({ isModalVisible: true })
            } else {
                var link = document.getElementById('href-link')

                //
                this.addClickNumber()

                link.click()
            }
        };

        const handleOk = () => {
            this.checkCode()
            // this.hrefLink()
            this.setState({ isModalVisible: false })
        };

        const handleCancel = () => {
            this.setState({ isModalVisible: false })
        };

        const setCode = (e) => {
            this.setState({ code: e.target.value })
        }

        return (
            <div id="download">

                <NavH5 infoId={this.props.match.params.id}></NavH5>

                <div id="down_content">
                    <div>
                        <img 
                            width={140}
                            height={140}
                            src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + this.state.info.thumbnail}
                        />
                    </div>

                    <div>
                        {this.state.info.name}
                    </div>

                    <div>
                        版本 {this.state.info.build}  <span> | </span> 更新时间：{this.state.info.create_date}
                    </div>

                    <div>
                        {this.state.info.type_name}
                    </div>

                    <div>
                        开发者：时代魔界
                    </div>

                    <div>
                        联系电话：{this.state.info.sale_mobile}
                    </div>

                    <div id="QRCode">
                        <QRCode 

                            value={this.state.url} 
                            size={180} 
                            fgColor="#000000" 
                            imageSettings={this.state.info.codeImage}
                        />
                    </div>

                    <div>
                        <a 
                            onClick={showModal}
                        >
                            点击下载
                        </a>
                        <a 
                            id="href-link"
                            href={this.state.info.link}
                            target="_blank"
                        >
                        </a>

                    </div>

                </div>

                    <div id="hr"></div>

                    <div id="down_title">
                        历史版本
                    </div>

                    <div id="down_table"  style={{ padding: '0 10%' }}>
                        <Table
                            columns={columns}
                            dataSource={this.state.versionList}
                            bordered={false}
                            pagination={false}
                            size="middle"
                        />
                    </div>

                    <div id="clear"></div>

                    <div id="hr"></div>

                    <div id="down_title">
                        图片预览
                    </div>

                    <div id="clear"></div>

                    <div style={{ padding: '0 10%' }}>
                        <Image
                            src={"http://fzsdmjdownload.oss-cn-hangzhou.aliyuncs.com/" + this.state.info.image}
                        />
                    </div>

                <div id="Bottom">
                    提示：本系统内所包含的程序、方案、视频、应用程序、源代码的著作权、版权和知识产权属于福州时代魔界网络技术有限公司所有。
                </div>

                <Modal title="请输入验证码" visible={this.state.isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p><Input onChange={setCode} /></p>
                </Modal>
              
            </div>
        );
    }
}

export default App;
